export default {
    
  methods: {
    accessReleased(routeName) {

      const routes = !this.$store.getters.getRoutesFromCompany ? [] : this.$store.getters.getRoutesFromCompany;
    
      if (this.$store.getters.getUserIsAdmin) {
        return true;
      } else {
        const route = this.$store.state.routes.appRoutes[routeName];
        return routes.filter(item => item === route)[0] ? true : false; 
      }
    }
  },
}
  