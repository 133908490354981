<template>
  <v-container fluid>
    <v-btn
      v-if="accessReleased('SIMULACOES_INDQUAL_ADICIONAR')"
      color="success"
      dark
      elevation="1"
      class="mt-5"
      style="float: left"
      relative
      text
      medium
      @click="$router.push('/geracao-bdgd/validacoes-indqual/simulacoes/add')"
    >
      <v-icon
        left
        size="30px"
      >
        mdi-plus-circle
      </v-icon>
      Simular
    </v-btn>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      class="ml-auto mt-4 break-search"
      label="Procurar"
      hide-details
      single-line
      style="max-width: 250px"
    />
    <general-progress-bars
      class="mt-11"
      :items="downloadProgressData"
      prefixText="Baixando logs ..."
    />
    <v-divider class="mt-10" />
    <v-data-table
      :headers="headers"
      :items="items"
      :search.sync="search"
      :mobile-breakpoint="800"
      :loading="loading"
      :hide-default-header="loading"
      :hide-default-footer="loading"
      no-data-text="Nenhuma simulação encontrada"
    >
      <template v-slot:[`item.status`]="{ item }">
        <general-status :status="item.status" />
      </template>
      <template v-slot:[`item.executada_em`]="{ item }">
        {{ item.executada_em | parseToDateTimeBR }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          :class="!podeBaixarZip(item.status) ? 'cursor-block' : ''"
          class="px-1"
          min-width="0"
          fab
          icon
          x-small
          @click="baixarZip(item)"
          :loading="downloadProgressData[item.id] !== undefined ? true : false"
          :disabled="!podeBaixarZip(item.status)"
        >
          <v-icon small> mdi-download </v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="action-more"
              color="white"
              class="ml-1"
              height="22px"
              width="22px"
              fab
              x-small
              elevation="1"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="canExecuteItem(item.status)"
              @click="executeItem(item.id)"
            >
              <v-list-item-title>
                <v-icon small> mdi-play </v-icon>
                Executar
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="canDeleteItem(item)"
              @click="openDialogDelete(item)"
            >
              <v-list-item-icon class="mx-0 my-0 py-4">
                <v-icon small>mdi-trash-can-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Deletar </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`footer.page-text`]>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="$emit('rechargeTable')"
            >
              mdi-refresh
            </v-icon>
          </template>
          <span> Clique aqui para recarregar as simulações </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="deletarItem(dialogDeleteData.id)"
    >
      <template slot="title">
        Deletar Simulação #{{ dialogDeleteData.id }}
      </template>
      <template slot="body">
        Tem certeza que deseja deletar a simulação
        <strong> #{{ dialogDeleteData.id }} </strong>?
      </template>
    </dialog-delete>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import SimulacoesIndqualService from '@/services/SimulacoesIndqualService';
import DialogDelete from '@/components/general/DialogDelete';
import GeneralStatus from '@/components/general/GeneralStatus';
import GeneralProgressBars from '@/components/general/GeneralProgressBars';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [powerupsActionsMixins, profilePermissionsMixin],
  components: {
    DialogDelete,
    GeneralStatus,
    GeneralProgressBars
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    headers: [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Título',
        value: 'nome'
      },
      {
        text: 'Tipo de simulação',
        value: 'tipo_simulacao'
      },

      {
        text: 'Executada por',
        value: 'executada_por'
      },
      {
        text: 'Executada em',
        value: 'executada_em'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        class: 'pl-4'
      }
    ],
    search: '',
    dialogDelete: false,
    dialogDeleteData: { id: null },
    downloadProgressData: {}
  }),
  methods: {
    canExecuteItem(status) {
      return status === 'SUCESSO' || status === 'FALHA';
    },
    executeItem(id) {
      if (!confirm(`Tem certeza que deseja executar novamente #${id}?`)) {
        return;
      }
      SimulacoesIndqualService.executar(id)
        .then(() => {
          this.$toast.info(
            'A execução ocorrerá em instantes. Por favor, aguarde.',
            '',
            { position: 'topRight', timeout: 2000 }
          );
          this.$emit('rechargeTable');
        })
        .catch(() => {
          this.$toast.error('Erro ao executar o ajuste de BDGD.', '', {
            position: 'topRight'
          });
        });
    },
    canDeleteItem(item) {
      return item.status === 'AGUARDANDO';
    },
    openDialogDelete(item) {
      this.dialogDelete = true;
      this.dialogDeleteData = {
        id: item.id
      };
    },
    deletarItem(id) {
      SimulacoesIndqualService.deletar(id)
        .then(() => {
          this.$toast.success('Simulação removida com sucesso.', '', {
            position: 'topRight'
          });
          this.$emit('rechargeTable');
        })
        .catch((error) => {
          this.$toast.error('Erro ao deletar a simulação.', '', {
            position: 'topRight'
          });
          console.error(error);
        });
      this.dialogDelete = false;
    },
    podeBaixarZip(status) {
      return status === 'SUCESSO' || status === 'FALHA';
    },
    baixarZip(simulacao) {
      const { id, tipo_simulacao_slug } = simulacao;
      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      const nomeArquivo = `simulacao-indqual-tipo-${tipo_simulacao_slug}-${id}-${timestamp}.zip`;

      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      SimulacoesIndqualService.baixarZip(id, {
        timeout: 60 * 60 * 1000,
        onDownloadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          let isComputable = progressEvent.lengthComputable;
          this.$set(this.downloadProgressData, id, {
            progress,
            isComputable,
            id
          });
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo zip com os logs da simulação do INDQUAL.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    }
  }
};
</script>
