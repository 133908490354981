<template>
  <v-container fluid>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <highcharts :options="chartOptions" />
    </div>
  </v-container>
</template>

<script>
import ValidacoesIndqualService from '@/services/ValidacoesIndqualService';

export default {
  name: 'GraficoTopConjuntosComMaisErrosPorMetodologia',
  props: {
    bdgdVersao: {
      type: Object,
      required: true
    },
    metodologiaAneel: {
      type: Object,
      required: true
    },
    numeroMaximoConjuntos: {
      type: Number,
      default: 10
    }
  },
  data: () => ({
    loading: false,
    resultado: []
  }),
  mounted() {
    this.getTopConjuntosComMaisErrosPorMetodologia();
  },
  methods: {
    getTopConjuntosComMaisErrosPorMetodologia() {
      if (!this.bdgdVersao || !this.metodologiaAneel) return;
      this.loading = true;
      ValidacoesIndqualService.getTopConjuntosComMaisErrosPorMetodologia(
        this.metodologiaAneel.id,
        this.bdgdVersao.id,
        this.numeroMaximoConjuntos
      )
        .then((response) => {
          this.resultado = response.data;
        })
        .catch(() => {
          this.$toast.error(
            `Erro ao buscar os top ${this.numeroMaximoConjuntos} conjuntos com mais erros para a metodologia ${this.metodologiaAneel.nome}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    }
  },
  computed: {
    topConjuntos() {
      return this.resultado.map(
        ({ codigo_conjunto, nome_conjunto }) =>
          `${codigo_conjunto} - ${nome_conjunto}`
      );
    },
    errosDec() {
      return this.resultado.map(({ total_erros_dec }) => total_erros_dec);
    },
    errosFec() {
      return this.resultado.map(({ total_erros_fec }) => total_erros_fec);
    },
    errosNuc() {
      return this.resultado.map(({ total_erros_nuc }) => total_erros_nuc);
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar'
        },
        title: {
          text: `${this.metodologiaAneel.nome} :: top ${this.numeroMaximoConjuntos} conjuntos com mais erros`
        },
        xAxis: {
          categories: this.topConjuntos
        },
        yAxis: {
          min: 0,
          title: {
            text: null
          }
        },
        legend: {
          reversed: true
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            dataLabels: {
              enabled: true
            }
          }
        },
        series: [
          {
            name: 'NUC',
            color: '#806000',
            data: this.errosNuc
          },
          {
            name: 'FEC',
            color: '#c00000',
            data: this.errosFec
          },
          {
            name: 'DEC',
            color: '#385623',
            data: this.errosDec
          }
        ]
      };
    }
  }
};
</script>
