var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.resultadoValidacao &&
    _vm.resultadoValidacao['resumo'] &&
    _vm.resultadoValidacao['resumo']['qtd_conjuntos']
  )?_c('div',{staticClass:"table-container"},[_c('table',{attrs:{"cellspacing":"0","border":"0"}},[_c('tr',{staticClass:"table-header"},[_c('td',[_vm._v("CONJ")]),_vm._l((_vm.resultadoValidacao['conj']),function(conj){return _c('td',{key:conj},[_vm._v(" "+_vm._s(conj)+" ")])})],2),_vm._l((_vm.meses),function(mes){return (_vm.exibirNumCons)?_c('tr',{key:("num_cons_" + mes)},[_c('td',{style:({
          background: '#BDD7EE',
          color: '#000000'
        })},[_vm._v(" NUM_CONS_"+_vm._s(mes)+" ")]),_vm._l((_vm.resultadoValidacao['conj']),function(conj,i){return _c('td',{key:i,attrs:{"title":("NUM_CONS_" + mes + " do CONJ " + conj)}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.resultadoValidacao[("nuc_" + mes)]['num_cons'][i]))+" ")])})],2):_vm._e()}),_vm._l((_vm.meses),function(mes){return (_vm.exibirNucInd)?_c('tr',{key:("nuc_ind_" + mes)},[_c('td',{style:({
          background: '#203864',
          color: '#ffffff'
        })},[_vm._v(" NUC_IND_"+_vm._s(mes)+" ")]),_vm._l((_vm.resultadoValidacao['conj']),function(conj,i){return _c('td',{key:i,attrs:{"title":("NUC_IND_" + mes + " do CONJ " + conj)}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.resultadoValidacao[("nuc_" + mes)]['nuc_ind'][i]))+" ")])})],2):_vm._e()}),_vm._l((_vm.meses),function(mes){return _c('tr',{key:("nuc_" + mes + "_perc")},[_c('td',{style:({
          background: '#BDD7EE',
          color: '#000000'
        })},[_vm._v(" NUC_"+_vm._s(mes)+"_PERC ")]),_vm._l((_vm.resultadoValidacao['conj']),function(conj,i){return _c('td',{key:i,style:({
          background:
            _vm.resultadoValidacao[("nuc_" + mes)]['status'][i] === 'OK'
              ? '#C6EFCE'
              : '#FFC7CE',
          color:
            _vm.resultadoValidacao[("nuc_" + mes)]['status'][i] === 'OK'
              ? '#006100'
              : '#9C0006'
        }),attrs:{"title":("NUC_" + mes + "_PERC do CONJ " + conj)}},[(_vm.statusConjuntos)?_c('span',[_vm._v(" "+_vm._s(_vm.resultadoValidacao[("nuc_" + mes)]['status'][i])+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.resultadoValidacao[("nuc_" + mes)]['perc'][i]))+" ")])])})],2)})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }