<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-file-compare"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          BDGD
          <v-progress-circular
            v-if="loading"
            size="20"
            indeterminate
            color="rgba(0, 0, 0, 0.87)"
          />
          <span v-else>
            {{ bdgdVersao.ordinaria ? 'Ordinária' : '' }}
            {{ bdgdVersao.data_registro | formatToMonth }} v{{
              bdgdVersao.versao
            }}
          </span>
          x INDQUAL
        </div>
      </template>
      <v-row>
        <v-col
          cols="12"
          class="d-flex flex-row-reverse"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="mt-n10"
                v-bind="attrs"
                v-on="on"
              >
                <v-btn
                  icon
                  color="black"
                  @click="downloadArquivoResultado"
                  :loading="loadingDownloadArquivoResultado"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="22"> mdi-download </v-icon>
                </v-btn>
              </div>
            </template>
            Baixar resultados INDQUAL
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="d-flex flex-row-reverse"
        >
          <router-link
            to="/geracao-bdgd/validacoes-indqual/painel-unificado"
            class="ml-4"
          >
            Painel Unificado INDQUAL
          </router-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-tabs
            centered
            fixed-tabs
            v-model="tabsLevel1"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              href="#tab-resumo"
              class="primary--text"
            >
              Resumo
            </v-tab>
            <v-tab
              href="#tab-importacoes"
              class="primary--text"
            >
              Importações INDQUAL
            </v-tab>
            <!-- <v-tab
              href="#tab-simulacoes"
              class="primary--text"
            >
              Simulações
            </v-tab> -->
            <v-tab
              v-for="metodologiaAneel in metodologiasAneelAtivas"
              :key="metodologiaAneel.classe"
              :value="metodologiaAneel.classe"
              class="primary--text"
            >
              {{ metodologiaAneel.nome }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabsLevel1">
            <v-tab-item value="tab-resumo">
              <v-row class="mt-4">
                <v-col cols="12">
                  <v-progress-circular
                    v-if="loading"
                    size="20"
                    indeterminate
                    color="rgba(0, 0, 0, 0.87)"
                  />
                  <span v-else>
                    <resumo-validacoes
                      :bdgdVersao="bdgdVersao"
                      :metodologiasAneelAtivas="metodologiasAneelAtivas"
                    />
                  </span>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-importacoes">
              <v-row class="mt-4">
                <v-col cols="6">
                  <tabela-resumo-importacoes-indicadores-dec-fec-nuc
                    class="mb-2"
                    :bdgdVersao="bdgdVersao"
                  />
                </v-col>
                <v-col cols="6">
                  <tabela-resumo-importacoes-entidades-bdgd
                    class="mb-2"
                    :bdgdVersao="bdgdVersao"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- <v-tab-item value="tab-simulacoes">
              <v-row class="mt-4">
                <v-col cols="12">
                  <simulacoes-indqual-data-table
                    :items="simulacoes"
                    :loading="loadingSimulacoes"
                    @rechargeTable="getSimulacoesIndqual"
                  />
                </v-col>
              </v-row>
            </v-tab-item> -->
            <v-tab-item
              v-for="metodologiaAneel in metodologiasAneelAtivas"
              :key="metodologiaAneel.classe"
              :value="metodologiaAneel.classe"
            >
              <v-row class="mt-4">
                <v-col cols="12">
                  <v-alert
                    text
                    color="info"
                  >
                    <v-row
                      align="center"
                      no-gutters
                    >
                      <v-col class="grow">
                        {{ metodologiaAneel.descricao }}
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row
                class="mt-4"
                align="center"
              >
                <v-col cols="12">
                  <component
                    v-bind:is="getFormExecucaoMetodologia(metodologiaAneel)"
                    :metodologiaAneel="metodologiaAneel"
                    :bdgdVersao="bdgdVersao"
                    @metodologiaExecutada="
                      processaMetodologiaExecutada(metodologiaAneel)
                    "
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="mt-4"
                  cols="12"
                >
                  <v-tabs
                    v-model="tabsLevel2[metodologiaAneel.id]"
                    fixed-tabs
                  >
                    <v-tab
                      href="#tab-dec-fec"
                      class="primary--text"
                    >
                      DEC/FEC
                    </v-tab>
                    <v-tab
                      href="#tab-nuc"
                      class="primary--text"
                      v-if="temTesteNuc(metodologiaAneel)"
                    >
                      NUC
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabsLevel2[metodologiaAneel.id]">
                    <v-tab-item value="tab-dec-fec">
                      <v-progress-circular
                        v-if="loading"
                        size="20"
                        indeterminate
                        color="rgba(0, 0, 0, 0.87)"
                      />
                      <resultado-validacao-dec-fec
                        v-else
                        :ref="`resultadoValidacaoDecFec${metodologiaAneel.codigo}`"
                        :metodologia-aneel="metodologiaAneel"
                        :bdgdVersao="bdgdVersao"
                      />
                    </v-tab-item>
                    <v-tab-item
                      value="tab-nuc"
                      v-if="temTesteNuc(metodologiaAneel)"
                    >
                      <v-progress-circular
                        v-if="loading"
                        size="20"
                        indeterminate
                        color="rgba(0, 0, 0, 0.87)"
                      />
                      <resultado-validacao-nuc
                        v-else
                        :ref="`resultadoValidacaoNuc${metodologiaAneel.codigo}`"
                        :metodologia-aneel="metodologiaAneel"
                        :bdgdVersao="bdgdVersao"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import ValidacoesIndqualService from '@/services/ValidacoesIndqualService';
import SimulacoesIndqualService from '@/services/SimulacoesIndqualService';
import ResumoValidacoes from '@/components/geracao-bdgd/indqual/ResumoValidacoes.vue';
import TabelaResumoImportacoesIndicadoresDecFecNuc from '@/components/geracao-bdgd/indqual/resumos-importacoes/TabelaResumoImportacoesIndicadoresDecFecNuc.vue';
import TabelaResumoImportacoesEntidadesBdgd from '@/components/geracao-bdgd/indqual/resumos-importacoes/TabelaResumoImportacoesEntidadesBdgd.vue';
import ResultadoValidacaoDecFec from '@/components/geracao-bdgd/indqual/ResultadoValidacaoDecFec.vue';
import ResultadoValidacaoNuc from '@/components/geracao-bdgd/indqual/ResultadoValidacaoNuc.vue';
import FormExecucaoMetodologia1 from '@/components/geracao-bdgd/indqual/FormExecucaoMetodologia1.vue';
import FormExecucaoMetodologia1a from '@/components/geracao-bdgd/indqual/FormExecucaoMetodologia1a.vue';
import FormExecucaoMetodologia1b from '@/components/geracao-bdgd/indqual/FormExecucaoMetodologia1b.vue';
import FormExecucaoMetodologia2 from '@/components/geracao-bdgd/indqual/FormExecucaoMetodologia2.vue';
import SimulacoesIndqualDataTable from '@/components/geracao-bdgd/indqual/simulacoes/SimulacoesIndqualDataTable.vue';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  name: 'ValidacoesIndqual',
  mixins: [profilePermissionsMixin, refreshDataMixins],
  components: {
    ResumoValidacoes,
    TabelaResumoImportacoesIndicadoresDecFecNuc,
    TabelaResumoImportacoesEntidadesBdgd,
    ResultadoValidacaoDecFec,
    ResultadoValidacaoNuc,
    FormExecucaoMetodologia1,
    FormExecucaoMetodologia1a,
    FormExecucaoMetodologia1b,
    FormExecucaoMetodologia2,
    SimulacoesIndqualDataTable
  },
  data: () => ({
    tabsLevel1: null,
    tabsLevel2: {},
    tabsKeySessionStorage: 'tab-validacoes-indqual',
    loading: false,
    loadingDownloadArquivoResultado: false,
    loadingSimulacoes: false,
    metodologiasAneelAtivas: [],
    simulacoes: []
  }),
  mounted() {
    this.getMetodologiasAneelAtivas();
    this.refreshData(this.getSimulacoesIndqual);
    let tabSelecionada = sessionStorage.getItem(this.tabsKeySessionStorage);
    if (!tabSelecionada) tabSelecionada = 'tab-resumo';
    this.tabsLevel1 = tabSelecionada;
  },
  computed: {
    bdgdVersao() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    }
  },
  methods: {
    getMetodologiasAneelAtivas() {
      this.loading = true;
      ValidacoesIndqualService.getMetodologiasAneelAtivas()
        .then((response) => {
          this.metodologiasAneelAtivas = response.data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar as metodologias ANEEL ativas no sistema.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loading = false));
    },
    getSimulacoesIndqual() {
      this.loadingSimulacoes = true;
      SimulacoesIndqualService.getAll()
        .then(({ data }) => (this.simulacoes = data.data))
        .finally(() => (this.loadingSimulacoes = false));
    },
    getFormExecucaoMetodologia(metodologiaAneel) {
      return `form-execucao-metodologia-${metodologiaAneel.codigo.toLowerCase()}`;
    },
    processaMetodologiaExecutada(metodologiaAneel) {
      const resultadoValidacaoDecFec =
        this.$refs[`resultadoValidacaoDecFec${metodologiaAneel.codigo}`];
      const resultadoValidacaoNuc =
        this.$refs[`resultadoValidacaoNuc${metodologiaAneel.codigo}`];
      if (resultadoValidacaoDecFec && resultadoValidacaoDecFec[0]) {
        resultadoValidacaoDecFec[0].getResultadoExecucaoMetodologia();
      }
      if (resultadoValidacaoNuc && resultadoValidacaoNuc[0]) {
        resultadoValidacaoNuc[0].getResultadoExecucaoMetodologia();
      }
    },
    downloadArquivoResultado() {
      this.loadingDownloadArquivoResultado = true;
      ValidacoesIndqualService.downloadArquivoResultado(this.bdgdVersao.id, {
        timeout: 60 * 60 * 1000
      })
        .then((res) => {
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const arquivo = `resultado-metodologias-indqual-${timestamp}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', arquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download do arquivo resultado.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingDownloadArquivoResultado = false));
    },
    temTesteNuc(metodologiaAneel) {
      return metodologiaAneel.codigo !== '1B';
    }
  },
  watch: {
    tabsLevel1() {
      sessionStorage.setItem(this.tabsKeySessionStorage, this.tabsLevel1);
    }
  }
};
</script>

<style>
.table-indqual-result table thead tr th,
.table-indqual-result table tbody tr td,
.table-indqual-result table tfoot tr td {
  font-size: 12px;
  white-space: nowrap;
  padding: 0px 8px !important;
}
.table-indqual-result table thead tr th {
  font-weight: 500;
}
.zebra1 {
  background-color: #fafafa;
}
.zebra2 {
  background-color: #f9fbe7;
}
.status-indqual-ok {
  font-size: 8px !important;
  background-color: #c6efce;
  color: #006100;
}
.status-indqual-erro {
  font-size: 8px !important;
  background: #ffc7ce;
  color: #9c0006;
}
.status-indqual-alerta {
  font-size: 8px !important;
  background: #ffeb9c;
  color: #9c5700;
}
</style>
