<template>
  <v-container fluid>
    <highcharts :options="chartOptions" />
  </v-container>
</template>

<script>
import Highcharts from 'highcharts';
import ValidacoesIndqualService from '@/services/ValidacoesIndqualService';

export default {
  name: 'GraficoStatusConjuntosPorMetodologia',
  props: {
    bdgdVersao: {
      type: Object,
      required: true
    },
    campoAnalise: {
      type: String,
      required: true
    }
  },
  data: () => ({
    resultado: {}
  }),
  computed: {
    numTotalConjuntosComErros() {
      return Object.keys(this.resultado)
        .map((codigo) => this.resultado[codigo])
        .reduce(
          (acumulador, item) =>
            (acumulador += Number(item.num_conjuntos_com_erros)),
          0
        );
    },
    seriesData() {
      return Object.keys(this.resultado).map((codigo) => {
        const dados = this.resultado[codigo];
        const percentual =
          this.numTotalConjuntosComErros === 0
            ? 0
            : dados.num_conjuntos_com_erros / this.numTotalConjuntosComErros;
        return {
          name: dados.metodologia_nome,
          y: 100 * percentual,
          value: dados.num_conjuntos_com_erros,
          drilldown: dados.metodologia_nome
        };
      });
    },
    seriesDrilldown() {
      return Object.keys(this.resultado).map((codigo) => {
        const dados = this.resultado[codigo];
        return {
          id: dados.metodologia_nome,
          name: dados.metodologia_nome,
          data: [
            {
              y: dados.num_conjuntos_ok,
              value: dados.num_conjuntos_ok,
              name: 'OK',
              color: '#8BC34A'
            },
            {
              y: dados.num_conjuntos_com_erros,
              value: dados.num_conjuntos_com_erros,
              name: 'DIF',
              color: '#E53935'
            }
          ]
        };
      });
    },
    chartOptions() {
      return {
        chart: {
          type: 'pie'
        },
        title: {
          text: `Conjuntos com Erros em ${this.campoAnalise}`
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format:
                '{point.name}: <br>{point.value} ({point.percentage:.1f}%)',
              style: {
                color:
                  (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                  'black'
              }
            },
            showInLegend: false
          }
        },
        tooltip: {
          pointFormat:
            '<b>Conjuntos: {point.value} ({point.percentage:.1f}%)</b>'
        },
        series: [
          {
            name: 'Metodologia',
            colorByPoint: true,
            data: this.seriesData
          }
        ],
        drilldown: {
          series: this.seriesDrilldown
        }
      };
    }
  },
  mounted() {
    this.getStatusConjuntosPorMetodologia();
  },
  methods: {
    getStatusConjuntosPorMetodologia() {
      if (!this.bdgdVersao || !this.campoAnalise) return;
      ValidacoesIndqualService.getStatusConjuntosPorMetodologia(
        this.bdgdVersao.id,
        this.campoAnalise
      )
        .then((response) => {
          this.resultado = response.data;
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao tentar buscar status dos conjuntos por metodologia.',
            '',
            { position: 'topRight' }
          );
        });
    }
  },
  watch: {
    bdgdVersao() {
      this.getStatusConjuntosPorMetodologia();
    }
  }
};
</script>
