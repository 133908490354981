<template>
  <div>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-simple-table
        dense
        class="table-indqual-result"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>BASE SIG-R</th>
              <th>LINHAS IMPORTADAS</th>
            </tr>
          </thead>
          <tbody></tbody>
          <tbody>
            <tr
              v-for="(item, j) in resultado"
              :key="j"
              :class="j % 2 === 1 ? 'zebra1' : ''"
            >
              <td>{{ item.entidade | toUpperCase }}</td>
              <td
                style="font-size: 12px !important"
                :class="{
                  'status-indqual-erro': item.registros === 0
                }"
              >
                {{ item.registros | parseNumberToIntegerBR }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import ValidacoesIndqualService from '@/services/ValidacoesIndqualService';

export default {
  name: 'TabelaResumoImportacoesEntidadesBdgd',
  props: {
    bdgdVersao: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    resultado: []
  }),
  mounted() {
    this.getResumoImportacoesEntidadesBdgd();
  },
  methods: {
    getResumoImportacoesEntidadesBdgd() {
      this.loading = true;
      ValidacoesIndqualService.getResumoImportacoesEntidadesBdgd(
        this.bdgdVersao.id
      )
        .then((response) => {
          this.resultado = response.data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar o resumo de importações das entidades relevantes da BDGD.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    bdgdVersao() {
      this.getResumoImportacoesEntidadesBdgd();
    }
  }
};
</script>
