var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":"20","indeterminate":"","color":"rgba(0, 0, 0, 0.87)"}}):_c('div',[_c('v-simple-table',{staticClass:"table-indqual-result",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("INDICADOR")]),_c('th',[_vm._v("MÊS")]),_c('th',{staticClass:"text-right"},[_vm._v("TOTAL CONJ")]),_c('th',{staticClass:"text-right"},[_vm._v("LINHAS IMPORTADAS")]),_c('th',{staticClass:"text-right"},[_vm._v("ATUALIZADO EM")])])]),_c('tbody'),_c('tbody',_vm._l((_vm.resultado),function(item,j){return _c('tr',{key:j,class:j % 2 === 1 ? 'zebra1' : ''},[_c('td',[_vm._v(_vm._s(item.sigla_indice))]),_c('td',[_vm._v(_vm._s(_vm._f("addLeadingZero")(item.mes)))]),_c('td',{staticClass:"text-right",class:{
                'status-indqual-erro':
                  item.total_conj === 0 ||
                  item.total_conj !== item.total_linhas_indicadores
              },staticStyle:{"font-size":"12px !important"}},[_vm._v(" "+_vm._s(item.total_conj)+" ")]),_c('td',{staticClass:"text-right",class:{
                'status-indqual-ok':
                  item.total_linhas_indicadores > 0 &&
                  item.total_conj === item.total_linhas_indicadores,
                'status-indqual-erro':
                  item.total_linhas_indicadores <= 0 ||
                  item.total_conj !== item.total_linhas_indicadores
              },staticStyle:{"font-size":"12px !important"}},[_vm._v(" "+_vm._s(item.total_linhas_indicadores)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.ultima_atualizacao_em))+" ")])])}),0)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }