<template>
  <div
    class="table-container"
    v-if="
      resultadoValidacao &&
      resultadoValidacao['resumo'] &&
      resultadoValidacao['resumo']['qtd_conjuntos']
    "
  >
    <table
      cellspacing="0"
      border="0"
    >
      <tr class="table-header">
        <td>CONJ</td>
        <td
          v-for="conj in resultadoValidacao['conj']"
          :key="conj"
        >
          {{ conj }}
        </td>
      </tr>
      <tr
        v-for="campoDec in camposDec"
        :key="campoDec"
      >
        <td
          :style="{
            background: '#BDD7EE',
            color: '#000000'
          }"
        >
          {{ campoDec.toUpperCase() }}_PERC
        </td>
        <td
          v-for="(conj, i) in resultadoValidacao['conj']"
          :key="i"
          :title="campoDec.toUpperCase() + '_PERC do CONJ ' + conj"
          :style="{
            background:
              resultadoValidacao[campoDec]['status'][i] === 'OK'
                ? '#C6EFCE'
                : '#FFC7CE',
            color:
              resultadoValidacao[campoDec]['status'][i] === 'OK'
                ? '#006100'
                : '#9C0006'
          }"
        >
          <span v-if="statusConjuntos">
            {{ resultadoValidacao[campoDec]['status'][i] }}
          </span>
          <span v-else>
            {{ resultadoValidacao[campoDec]['perc'][i] | parseNumberToFloatBR }}
          </span>
        </td>
      </tr>
      <tr
        v-for="campoFec in camposFec"
        :key="campoFec"
      >
        <td
          :style="{
            background: '#203864',
            color: '#ffffff'
          }"
        >
          {{ campoFec.toUpperCase() }}_PERC
        </td>
        <td
          v-for="(conj, i) in resultadoValidacao['conj']"
          :key="i"
          :title="campoFec.toUpperCase() + '_PERC do CONJ ' + conj"
          :style="{
            background:
              resultadoValidacao[campoFec]['status'][i] === 'OK'
                ? '#C6EFCE'
                : '#FFC7CE',
            color:
              resultadoValidacao[campoFec]['status'][i] === 'OK'
                ? '#006100'
                : '#9C0006'
          }"
        >
          <span v-if="statusConjuntos">
            {{ resultadoValidacao[campoFec]['status'][i] }}
          </span>
          <span v-else>
            {{ resultadoValidacao[campoFec]['perc'][i] | parseNumberToFloatBR }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TabelaResultadoValidacaoDecFec',
  props: {
    resultadoValidacao: {
      type: Object,
      required: true
    },
    statusConjuntos: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    camposDec() {
      return [
        'dec_01',
        'dec_02',
        'dec_03',
        'dec_04',
        'dec_05',
        'dec_06',
        'dec_07',
        'dec_08',
        'dec_09',
        'dec_10',
        'dec_11',
        'dec_12'
      ];
    },
    camposFec() {
      return [
        'fec_01',
        'fec_02',
        'fec_03',
        'fec_04',
        'fec_05',
        'fec_06',
        'fec_07',
        'fec_08',
        'fec_09',
        'fec_10',
        'fec_11',
        'fec_12'
      ];
    }
  }
};
</script>

<style>
.table-container {
  max-width: 100%;
  overflow-x: scroll;
  padding-bottom: 20px;
}
.table-container table td {
  font-family: 'Times New Roman', Times, serif;
  font-size: 12px;
  border: 1px solid #000000;
  padding: 5px;
  text-align: center;
}
.table-header {
  background-color: #ffe699;
  font-weight: bold;
  text-align: center;
}
</style>
