<template>
  <div>
    <v-row
      class="mt-4"
      align="center"
    >
      <v-col cols="2">
        <div class="mb-2">
          <v-switch
            v-model="conjuntosComErros"
            hide-details
            inset
            label="Conjuntos com erros"
          />
        </div>
        <div class="mb-2">
          <v-switch
            v-model="statusConjuntos"
            hide-details
            inset
            label="Status dos conjuntos"
          />
        </div>
      </v-col>
      <v-col cols="5">
        <v-textarea
          v-model="buscarPorConjunto"
          append-icon="mdi-magnify"
          class="mx-2"
          label="Buscar por conjunto"
          placeholder="Informe um código de conjunto por linha"
          rows="4"
          no-resize
          clearable
        />
      </v-col>
      <v-col cols="3">
        <div
          class="mb-2"
          :style="{ color: qtdConjuntosComErros === 0 ? 'green' : '#e91e63' }"
        >
          Qtd de conjuntos com erros:
          {{ qtdConjuntosComErros | parseNumberToIntegerBR }}
        </div>
        <div
          class="mb-2"
          :style="{ color: qtdConjuntosOk > 0 ? 'green' : '#e91e63' }"
        >
          Qtd de conjuntos ok:
          {{ qtdConjuntosOk | parseNumberToIntegerBR }}
        </div>
        <div
          class="mb-2"
          :style="{ color: qtdConjuntos > 0 ? 'green' : '#e91e63' }"
        >
          Qtd de conjuntos:
          {{ qtdConjuntos | parseNumberToIntegerBR }}
        </div>
      </v-col>
      <v-col cols="2">
        <v-btn
          title="Atualizar o resultado utilizando os filtros selecionados"
          outlined
          @click="getResultadoExecucaoMetodologia"
          :loading="loading"
        >
          <v-icon color="primary"> mdi-refresh </v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          title="Baixar o resultado"
          outlined
          @click="baixarResultadoValidacaoDecFec"
          :loading="loadingBaixarResultadoValidacaoDecFec"
        >
          <v-icon color="primary"> mdi-download </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      class="mt-4"
      align="center"
    >
      <v-col cols="12">
        <v-progress-circular
          v-if="loading"
          size="20"
          indeterminate
          color="rgba(0, 0, 0, 0.87)"
        />
        <tabela-resultado-validacao-dec-fec
          v-else
          :resultadoValidacao="resultado"
          :statusConjuntos="statusConjuntos"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ValidacoesIndqualService from '@/services/ValidacoesIndqualService';
import TabelaResultadoValidacaoDecFec from '@/components/geracao-bdgd/indqual/TabelaResultadoValidacaoDecFec.vue';
import BaixarResultadoValidacaoMixins from './BaixarResultadoValidacaoMixins';

export default {
  name: 'ResultadoValidacaoDecFec',
  mixins: [BaixarResultadoValidacaoMixins],
  components: {
    TabelaResultadoValidacaoDecFec
  },
  props: {
    metodologiaAneel: {
      type: Object,
      required: true
    },
    bdgdVersao: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    conjuntosComErros: false,
    statusConjuntos: false,
    buscarPorConjunto: null,
    resultado: {}
  }),
  mounted() {
    this.getResultadoExecucaoMetodologia();
  },
  methods: {
    getResultadoExecucaoMetodologia() {
      this.loading = true;
      const filtros = {
        conjuntos_com_erros: this.conjuntosComErros,
        status_conjuntos: this.statusConjuntos,
        buscar_por_conjunto: this.buscarPorConjunto
      };
      ValidacoesIndqualService.getResultadoDecFecExecucaoMetodologia(
        this.metodologiaAneel.id,
        this.bdgdVersao.id,
        filtros
      )
        .then((response) => {
          this.resultado = response.data;
        })
        .catch(() => {
          this.$toast.error(
            `Erro ao tentar buscar os resultados da ${this.metodologiaAneel.nome}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    }
  },
  computed: {
    qtdConjuntos() {
      return this.resultado &&
        this.resultado['resumo'] &&
        this.resultado['resumo']['qtd_conjuntos']
        ? this.resultado['resumo']['qtd_conjuntos']
        : 0;
    },
    qtdConjuntosOk() {
      return this.resultado &&
        this.resultado['resumo'] &&
        this.resultado['resumo']['qtd_conjuntos_ok']
        ? this.resultado['resumo']['qtd_conjuntos_ok']
        : 0;
    },
    qtdConjuntosComErros() {
      return this.resultado &&
        this.resultado['resumo'] &&
        this.resultado['resumo']['qtd_conjuntos_erros']
        ? this.resultado['resumo']['qtd_conjuntos_erros']
        : 0;
    }
  }
};
</script>
