<template>
  <v-expansion-panels focusable>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Executar {{ metodologiaAneel.nome }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row
          class="mt-4"
          align="center"
        >
          <v-col cols="3">
            <v-text-field
              v-model="parametros_execucao.perc_validacao_dec_fec_limite"
              label="perc_validacao_dec_fec_limite"
              type="number"
              step="1"
            />
          </v-col>
          <v-col cols="1"> % </v-col>
          <v-col cols="8">
            <v-btn
              title="Executar a metodologia com os parâmetros informados"
              outlined
              @click="executarMetodologia()"
              :loading="loading"
              :disabled="!podeExecutarMetodologia"
            >
              Executar
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ValidacoesIndqualService from '@/services/ValidacoesIndqualService';

export default {
  name: 'FormExecucaoMetodologia1b',
  props: {
    metodologiaAneel: {
      type: Object,
      required: true
    },
    bdgdVersao: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    parametros_execucao: {
      perc_validacao_dec_fec_limite: 5
    }
  }),
  computed: {
    podeExecutarMetodologia() {
      const { perc_validacao_dec_fec_limite } = this.parametros_execucao;
      return perc_validacao_dec_fec_limite !== '';
    }
  },
  methods: {
    executarMetodologia() {
      this.loading = true;
      ValidacoesIndqualService.executarMetodologia(
        this.metodologiaAneel.id,
        this.bdgdVersao.id,
        this.parametros_execucao
      )
        .then(() => {
          this.$toast.success(
            `${this.metodologiaAneel.nome} executada com sucesso.`,
            '',
            { position: 'topRight' }
          );
          this.$emit('metodologiaExecutada');
        })
        .catch(() => {
          this.$toast.error(
            `Erro ao tentar executar a ${this.metodologiaAneel.nome}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
