var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.resultadoValidacao &&
    _vm.resultadoValidacao['resumo'] &&
    _vm.resultadoValidacao['resumo']['qtd_conjuntos']
  )?_c('div',{staticClass:"table-container"},[_c('table',{attrs:{"cellspacing":"0","border":"0"}},[_c('tr',{staticClass:"table-header"},[_c('td',[_vm._v("CONJ")]),_vm._l((_vm.resultadoValidacao['conj']),function(conj){return _c('td',{key:conj},[_vm._v(" "+_vm._s(conj)+" ")])})],2),_vm._l((_vm.camposDec),function(campoDec){return _c('tr',{key:campoDec},[_c('td',{style:({
          background: '#BDD7EE',
          color: '#000000'
        })},[_vm._v(" "+_vm._s(campoDec.toUpperCase())+"_PERC ")]),_vm._l((_vm.resultadoValidacao['conj']),function(conj,i){return _c('td',{key:i,style:({
          background:
            _vm.resultadoValidacao[campoDec]['status'][i] === 'OK'
              ? '#C6EFCE'
              : '#FFC7CE',
          color:
            _vm.resultadoValidacao[campoDec]['status'][i] === 'OK'
              ? '#006100'
              : '#9C0006'
        }),attrs:{"title":campoDec.toUpperCase() + '_PERC do CONJ ' + conj}},[(_vm.statusConjuntos)?_c('span',[_vm._v(" "+_vm._s(_vm.resultadoValidacao[campoDec]['status'][i])+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.resultadoValidacao[campoDec]['perc'][i]))+" ")])])})],2)}),_vm._l((_vm.camposFec),function(campoFec){return _c('tr',{key:campoFec},[_c('td',{style:({
          background: '#203864',
          color: '#ffffff'
        })},[_vm._v(" "+_vm._s(campoFec.toUpperCase())+"_PERC ")]),_vm._l((_vm.resultadoValidacao['conj']),function(conj,i){return _c('td',{key:i,style:({
          background:
            _vm.resultadoValidacao[campoFec]['status'][i] === 'OK'
              ? '#C6EFCE'
              : '#FFC7CE',
          color:
            _vm.resultadoValidacao[campoFec]['status'][i] === 'OK'
              ? '#006100'
              : '#9C0006'
        }),attrs:{"title":campoFec.toUpperCase() + '_PERC do CONJ ' + conj}},[(_vm.statusConjuntos)?_c('span',[_vm._v(" "+_vm._s(_vm.resultadoValidacao[campoFec]['status'][i])+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.resultadoValidacao[campoFec]['perc'][i]))+" ")])])})],2)})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }