<template>
  <v-container fluid>
    <table
      cellspacing="0"
      border="0"
      class="tabela-resumo-validacoes"
    >
      <colgroup width="200"></colgroup>
      <colgroup width="200"></colgroup>
      <colgroup width="200"></colgroup>
      <colgroup width="200"></colgroup>
      <colgroup width="250"></colgroup>
      <colgroup width="200"></colgroup>
      <colgroup width="200"></colgroup>
      <colgroup width="200"></colgroup>
      <tr class="header">
        <td
          height="70"
          valign="middle"
        >
          Simulações
        </td>
        <td valign="middle">Conjuntos</td>
        <td valign="middle">
          Total de verificações <br />
          DEC;FEC;NUC
        </td>
        <td valign="middle">Total de erros críticos</td>
        <td valign="middle">% Total erros / Total verificações</td>
        <td valign="middle">Erros críticos DEC</td>
        <td valign="middle">Erros críticos FEC</td>
        <td valign="middle">Erros críticos NUC</td>
      </tr>
      <tr
        v-for="(metodologiaAneel, i) in metodologiasAneelAtivas"
        :key="i"
      >
        <td height="30">
          <strong>{{ i + 1 }} - {{ metodologiaAneel.nome }}</strong>
        </td>
        <td>
          {{
            getResumoMetodologia(metodologiaAneel).total_conjuntos
              | parseNumberToIntegerBR
          }}
        </td>
        <td>
          {{
            getResumoMetodologia(metodologiaAneel).total_verificacoes
              | parseNumberToIntegerBR
          }}
        </td>
        <td>
          {{
            getResumoMetodologia(metodologiaAneel).total_erros_criticos
              | parseNumberToIntegerBR
          }}
        </td>
        <td>
          {{
            getResumoMetodologia(metodologiaAneel).perc_erros_criticos
              | parseNumberToFloatBR
          }}%
        </td>
        <td>
          {{
            getResumoMetodologia(metodologiaAneel).total_erros_criticos_dec
              | parseNumberToIntegerBR
          }}
        </td>
        <td>
          {{
            getResumoMetodologia(metodologiaAneel).total_erros_criticos_fec
              | parseNumberToIntegerBR
          }}
        </td>
        <td>
          {{
            getResumoMetodologia(metodologiaAneel).total_erros_criticos_nuc
              | parseNumberToIntegerBR
          }}
        </td>
      </tr>
    </table>
  </v-container>
</template>

<script>
export default {
  name: 'TabelaResumoValidacoes',
  props: {
    resumoValidacoes: {
      type: Object,
      required: true
    },
    metodologiasAneelAtivas: {
      type: Array,
      required: true
    }
  },
  methods: {
    getResumoMetodologia(metodologiaAneel) {
      if (!this.resumoValidacoes[metodologiaAneel.codigo]) {
        return {
          total_conjuntos: 0,
          total_verificacoes: 0,
          total_erros_criticos: 0,
          total_erros_criticos: 0,
          perc_erros_criticos: 0,
          total_erros_criticos_dec: 0,
          total_erros_criticos_fec: 0,
          total_erros_criticos_nuc: 0
        };
      }
      return this.resumoValidacoes[metodologiaAneel.codigo][
        'resumo_metodologia'
      ];
    }
  }
};
</script>

<style>
.tabela-resumo-validacoes td {
  font-family: 'Times New Roman', Times, serif;
  font-size: 12px;
  border-bottom: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  text-align: center;
}
.tabela-resumo-validacoes .header {
  background-color: #44546a;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}
</style>
