<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-progress-circular
          v-if="loading"
          size="20"
          indeterminate
          color="rgba(0, 0, 0, 0.87)"
        />
        <v-row v-else>
          <v-col
            align="center"
            justify="center"
            cols="12"
          >
            <tabela-resumo-validacoes
              :metodologiasAneelAtivas="metodologiasAneelAtivas"
              :resumoValidacoes="resultado"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-progress-circular
          v-if="loading"
          size="20"
          indeterminate
          color="rgba(0, 0, 0, 0.87)"
        />
        <v-row v-else>
          <v-col
            align="center"
            justify="center"
            cols="12"
          >
            <grafico-simulacoes-bdgd-indqual
              :metodologiasAneelAtivas="metodologiasAneelAtivas"
              :resumoValidacoes="resultado"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-progress-circular
          v-if="loading"
          size="20"
          indeterminate
          color="rgba(0, 0, 0, 0.87)"
        />
        <div v-else>
          <grafico-status-conjuntos-por-metodologia
            :bdgdVersao="bdgdVersao"
            campoAnalise="DEC"
          />
        </div>
      </v-col>
      <v-col cols="4">
        <v-progress-circular
          v-if="loading"
          size="20"
          indeterminate
          color="rgba(0, 0, 0, 0.87)"
        />
        <div v-else>
          <grafico-status-conjuntos-por-metodologia
            :bdgdVersao="bdgdVersao"
            campoAnalise="FEC"
          />
        </div>
      </v-col>
      <v-col cols="4">
        <v-progress-circular
          v-if="loading"
          size="20"
          indeterminate
          color="rgba(0, 0, 0, 0.87)"
        />
        <div v-else>
          <grafico-status-conjuntos-por-metodologia
            :bdgdVersao="bdgdVersao"
            campoAnalise="NUC"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        v-for="metodologiaAneel in metodologiasAneelAtivas"
        :key="metodologiaAneel.id"
      >
        <grafico-top-conjuntos-com-mais-erros-por-metodologia
          :bdgdVersao="bdgdVersao"
          :metodologiaAneel="metodologiaAneel"
          :numeroMaximoConjuntos="10"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ValidacoesIndqualService from '@/services/ValidacoesIndqualService';
import TabelaResumoValidacoes from '@/components/geracao-bdgd/indqual/TabelaResumoValidacoes.vue';
import GraficoSimulacoesBdgdIndqual from '@/components/geracao-bdgd/indqual/GraficoSimulacoesBdgdIndqual.vue';
import GraficoStatusConjuntosPorMetodologia from '@/components/geracao-bdgd/indqual/GraficoStatusConjuntosPorMetodologia.vue';
import GraficoTopConjuntosComMaisErrosPorMetodologia from '@/components/geracao-bdgd/indqual/GraficoTopConjuntosComMaisErrosPorMetodologia.vue';

export default {
  name: 'ResumoValidacoes',
  components: {
    TabelaResumoValidacoes,
    GraficoSimulacoesBdgdIndqual,
    GraficoStatusConjuntosPorMetodologia,
    GraficoTopConjuntosComMaisErrosPorMetodologia
  },
  props: {
    bdgdVersao: {
      type: Object,
      required: true
    },
    metodologiasAneelAtivas: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    loading: false,
    resultado: {}
  }),
  mounted() {
    this.getResumoValidacoes();
  },
  methods: {
    getResumoValidacoes() {
      if (!this.bdgdVersao) return;
      this.loading = true;
      ValidacoesIndqualService.getResumoValidacoes(this.bdgdVersao.id)
        .then((response) => {
          this.resultado = response.data;
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao tentar buscar o resumo das validações INDQUAL x BDGD.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
