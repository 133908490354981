<template>
  <v-container fluid>
    <highcharts :options="chartOptions" />
  </v-container>
</template>

<script>
export default {
  name: 'GraficoSimulacoesBdgdIndqual',
  props: {
    resumoValidacoes: {
      type: Object,
      required: true
    },
    metodologiasAneelAtivas: {
      type: Array,
      required: true
    }
  },
  computed: {
    metodologias() {
      return this.metodologiasAneelAtivas.map(
        (metodologia) => metodologia.nome
      );
    },
    errosCriticosDec() {
      return this.getErrosCriticosPorCampo('dec');
    },
    errosCriticosFec() {
      return this.getErrosCriticosPorCampo('fec');
    },
    errosCriticosNuc() {
      return this.getErrosCriticosPorCampo('nuc');
    },
    chartOptions() {
      return {
        chart: {
          type: 'column',
          style: {
            fontFamily: 'muli'
          }
        },
        title: {
          text: 'Simulações - BDGD x INDQUAL'
        },
        xAxis: {
          categories: this.metodologias
        },
        yAxis: {
          min: 0,
          title: {
            text: ''
          },
          stackLabels: {
            enabled: true
          }
        },
        tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true
            }
          }
        },
        series: [
          {
            name: 'Erros críticos DEC',
            color: '#385623',
            data: this.errosCriticosDec
          },
          {
            name: 'Erros críticos FEC',
            color: '#c00000',
            data: this.errosCriticosFec
          },
          {
            name: 'Erros críticos NUC',
            color: '#806000',
            data: this.errosCriticosNuc
          }
        ]
      };
    }
  },
  methods: {
    getErrosCriticosPorCampo(campo) {
      let errosCriticos = [];
      if (!this.metodologiasAneelAtivas) return errosCriticos;
      this.metodologiasAneelAtivas.forEach((metodologia) => {
        if (!this.resumoValidacoes[metodologia.codigo]) return;
        let totalErrosCriticos =
          this.resumoValidacoes[metodologia.codigo]['resumo_metodologia'][
            `total_erros_criticos_${campo}`
          ];
        errosCriticos.push(totalErrosCriticos);
      });
      return errosCriticos;
    }
  }
};
</script>
