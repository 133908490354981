<template>
  <div
    class="table-container"
    v-if="
      resultadoValidacao &&
      resultadoValidacao['resumo'] &&
      resultadoValidacao['resumo']['qtd_conjuntos']
    "
  >
    <table
      cellspacing="0"
      border="0"
    >
      <tr class="table-header">
        <td>CONJ</td>
        <td
          v-for="conj in resultadoValidacao['conj']"
          :key="conj"
        >
          {{ conj }}
        </td>
      </tr>
      <tr
        v-if="exibirNumCons"
        v-for="mes in meses"
        :key="`num_cons_${mes}`"
      >
        <td
          :style="{
            background: '#BDD7EE',
            color: '#000000'
          }"
        >
          NUM_CONS_{{ mes }}
        </td>
        <td
          v-for="(conj, i) in resultadoValidacao['conj']"
          :key="i"
          :title="`NUM_CONS_${mes} do CONJ ${conj}`"
        >
          {{
            resultadoValidacao[`nuc_${mes}`]['num_cons'][i]
              | parseNumberToFloatBR
          }}
        </td>
      </tr>
      <tr
        v-if="exibirNucInd"
        v-for="mes in meses"
        :key="`nuc_ind_${mes}`"
      >
        <td
          :style="{
            background: '#203864',
            color: '#ffffff'
          }"
        >
          NUC_IND_{{ mes }}
        </td>
        <td
          v-for="(conj, i) in resultadoValidacao['conj']"
          :key="i"
          :title="`NUC_IND_${mes} do CONJ ${conj}`"
        >
          {{
            resultadoValidacao[`nuc_${mes}`]['nuc_ind'][i]
              | parseNumberToFloatBR
          }}
        </td>
      </tr>
      <tr
        v-for="mes in meses"
        :key="`nuc_${mes}_perc`"
      >
        <td
          :style="{
            background: '#BDD7EE',
            color: '#000000'
          }"
        >
          NUC_{{ mes }}_PERC
        </td>
        <td
          v-for="(conj, i) in resultadoValidacao['conj']"
          :key="i"
          :title="`NUC_${mes}_PERC do CONJ ${conj}`"
          :style="{
            background:
              resultadoValidacao[`nuc_${mes}`]['status'][i] === 'OK'
                ? '#C6EFCE'
                : '#FFC7CE',
            color:
              resultadoValidacao[`nuc_${mes}`]['status'][i] === 'OK'
                ? '#006100'
                : '#9C0006'
          }"
        >
          <span v-if="statusConjuntos">
            {{ resultadoValidacao[`nuc_${mes}`]['status'][i] }}
          </span>
          <span v-else>
            {{
              resultadoValidacao[`nuc_${mes}`]['perc'][i] | parseNumberToFloatBR
            }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TabelaResultadoValidacaoNuc',
  props: {
    resultadoValidacao: {
      type: Object,
      required: true
    },
    statusConjuntos: {
      type: Boolean,
      default: false
    },
    exibirNumCons: {
      type: Boolean,
      default: false
    },
    exibirNucInd: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    meses() {
      return [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12'
      ];
    }
  }
};
</script>

<style>
.table-container {
  max-width: 100%;
  overflow-x: scroll;
  padding-bottom: 20px;
}
.table-container table td {
  font-family: 'Times New Roman', Times, serif;
  font-size: 12px;
  border: 1px solid #000000;
  padding: 5px;
  text-align: center;
}
.table-header {
  background-color: #ffe699;
  font-weight: bold;
  text-align: center;
}
</style>
