import APIService from './APIService';

export default {
  getMetodologiasAneelAtivas() {
    return APIService.apiCall().get('validacoes-indqual/get-metodologias-aneel-ativas');
  },
  getResumoValidacoes(bdgdVersaoId) {
    return APIService.apiCall().get(`validacoes-indqual/get-resumo-validacoes/${bdgdVersaoId}`);
  },
  executarMetodologia(metodologiaId, bdgdVersaoId, postData) {
    return APIService.apiCall().post(
      `validacoes-indqual/executar-metodologia/${metodologiaId}/${bdgdVersaoId}`,
      JSON.stringify(postData)
    );
  },
  getResultadoDecFecExecucaoMetodologia(metodologiaId, bdgdVersaoId, postData) {
    return APIService.apiCall().post(
      `validacoes-indqual/get-resultado-dec-fec-execucao-metodologia/${metodologiaId}/${bdgdVersaoId}`,
      JSON.stringify(postData)
    );
  },
  baixarResultadoDecFecExecucaoMetodologia(metodologiaId, bdgdVersaoId, postData, config) {
    return APIService.apiCall().post(
      `validacoes-indqual/baixar-resultado-dec-fec-execucao-metodologia/${metodologiaId}/${bdgdVersaoId}`,
      JSON.stringify(postData),
      { responseType: 'blob', ...config }
    );
  },
  getResultadoNucExecucaoMetodologia(metodologiaId, bdgdVersaoId, postData) {
    return APIService.apiCall().post(
      `validacoes-indqual/get-resultado-nuc-execucao-metodologia/${metodologiaId}/${bdgdVersaoId}`,
      JSON.stringify(postData)
    );
  },
  baixarResultadoNucExecucaoMetodologia(metodologiaId, bdgdVersaoId, postData, config) {
    return APIService.apiCall().post(
      `validacoes-indqual/baixar-resultado-nuc-execucao-metodologia/${metodologiaId}/${bdgdVersaoId}`,
      JSON.stringify(postData),
      { responseType: 'blob', ...config }
    );
  },
  downloadArquivoResultado(bdgdVersaoId, config) {
    return APIService.apiCall().get(
      `validacoes-indqual/download-arquivo-resultado/${bdgdVersaoId}`,
      { responseType: 'blob', ...config }
    );
  },
  getStatusConjuntosPorMetodologia(
    bdgdVersaoId,
    campoAnalise='DEC'
  ) {
    return APIService.apiCall().get(`validacoes-indqual/get-status-conjuntos-por-metodologia/${bdgdVersaoId}/${campoAnalise}`);
  },
  getTopConjuntosComMaisErrosPorMetodologia(
    metodologiaId,
    bdgdVersaoId,
    numeroMaximoConjuntos
  ) {
    return APIService.apiCall().get(`validacoes-indqual/get-top-conjuntos-com-mais-erros-por-metodologia/${metodologiaId}/${bdgdVersaoId}/${numeroMaximoConjuntos}`);
  },
  getResumoConjuntosComErros() {
    return APIService.apiCall().get('validacoes-indqual/get-resumo-conjuntos-com-erros');
  },
  getResumoConjuntosAprovados() {
    return APIService.apiCall().get('validacoes-indqual/get-resumo-conjuntos-aprovados');
  },
  getResumoValidacoesPainelUnificado() {
    return APIService.apiCall().get('validacoes-indqual/get-resumo-validacoes-painel-unificado');
  },
  getResumoStatusConjuntosPorMetodologiaPainelUnificado() {
    return APIService.apiCall().get('validacoes-indqual/get-resumo-status-conjuntos-por-metodologia-painel-unificado');
  },
  getResumoImportacoesIndicadoresDecFecNuc(bdgdVersaoId) {
    return APIService.apiCall().get(
      `validacoes-indqual/get-resumo-importacoes-indicadores-dec-fec-nuc/${bdgdVersaoId}`
    );
  },
  getResumoImportacoesEntidadesBdgd(bdgdVersaoId) {
    return APIService.apiCall().get(
      `validacoes-indqual/get-resumo-importacoes-entidades-bdgd/${bdgdVersaoId}`
    );
  },
};
