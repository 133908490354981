import dayjs from 'dayjs';
import ValidacoesIndqualService from '@/services/ValidacoesIndqualService';

export default {
  data: () => ({
    loadingBaixarResultadoValidacaoDecFec: false,
    loadingBaixarResultadoValidacaoNuc: false
  }),
  computed: {
    metodologiaAneelSlug() {
      return this.metodologiaAneel.nome.toLowerCase().replaceAll(' ', '-');
    }
  },
  methods: {
    baixarResultadoValidacaoDecFec() {
      this.loadingBaixarResultadoValidacaoDecFec = true;
      const filtros = {
        conjuntos_com_erros: this.conjuntosComErros,
        status_conjuntos: this.statusConjuntos,
        buscar_por_conjunto: this.buscarPorConjunto
      };
      ValidacoesIndqualService.baixarResultadoDecFecExecucaoMetodologia(
        this.metodologiaAneel.id,
        this.bdgdVersao.id,
        filtros,
        {
          timeout: 60 * 60 * 1000
        }
      )
      .then((res) => {
        const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
        const slug = this.metodologiaAneelSlug;
        const arquivo = `resultado-${slug}-dec-fec-${timestamp}.xlsx`;
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          arquivo
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        this.$toast.error('Falha no download do arquivo resultado.', '', {
          position: 'topRight'
        });
      })
      .finally(() => (this.loadingBaixarResultadoValidacaoDecFec = false));
    },
    baixarResultadoValidacaoNuc() {
      this.loadingBaixarResultadoValidacaoNuc = true;
      const filtros = {
        conjuntos_com_erros: this.conjuntosComErros,
        status_conjuntos: this.statusConjuntos,
        buscar_por_conjunto: this.buscarPorConjunto,
        exibir_num_cons: this.exibirNumCons,
        exibir_nuc_ind: this.exibirNucInd
      };
      ValidacoesIndqualService.baixarResultadoNucExecucaoMetodologia(
        this.metodologiaAneel.id,
        this.bdgdVersao.id,
        filtros,
        {
          timeout: 60 * 60 * 1000
        }
      )
      .then((res) => {
        const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
        const slug = this.metodologiaAneelSlug;
        const arquivo = `resultado-${slug}-nuc-${timestamp}.xlsx`;
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          arquivo
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        this.$toast.error('Falha no download do arquivo resultado.', '', {
          position: 'topRight'
        });
      })
      .finally(() => (this.loadingBaixarResultadoValidacaoNuc = false));
    }
  }
} 