var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.accessReleased('SIMULACOES_INDQUAL_ADICIONAR'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('/geracao-bdgd/validacoes-indqual/simulacoes/add')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Simular ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('general-progress-bars',{staticClass:"mt-11",attrs:{"items":_vm.downloadProgressData,"prefixText":"Baixando logs ..."}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhuma simulação encontrada"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.executada_em",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.executada_em))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",class:!_vm.podeBaixarZip(item.status) ? 'cursor-block' : '',attrs:{"min-width":"0","fab":"","icon":"","x-small":"","loading":_vm.downloadProgressData[item.id] !== undefined ? true : false,"disabled":!_vm.podeBaixarZip(item.status)},on:{"click":function($event){return _vm.baixarZip(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(_vm.canExecuteItem(item.status))?_c('v-list-item',{on:{"click":function($event){return _vm.executeItem(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")]),_vm._v(" Executar ")],1)],1):_vm._e(),(_vm.canDeleteItem(item))?_c('v-list-item',{on:{"click":function($event){return _vm.openDialogDelete(item)}}},[_c('v-list-item-icon',{staticClass:"mx-0 my-0 py-4"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1),_c('v-list-item-title',[_vm._v(" Deletar ")])],1):_vm._e()],1)],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('rechargeTable')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v(" Clique aqui para recarregar as simulações ")])])]},proxy:true}],null,true)}),_c('dialog-delete',{attrs:{"dialog":_vm.dialogDelete},on:{"update:dialog":function($event){_vm.dialogDelete=$event},"submitTriggered":function($event){return _vm.deletarItem(_vm.dialogDeleteData.id)}}},[_c('template',{slot:"title"},[_vm._v(" Deletar Simulação #"+_vm._s(_vm.dialogDeleteData.id)+" ")]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja deletar a simulação "),_c('strong',[_vm._v(" #"+_vm._s(_vm.dialogDeleteData.id)+" ")]),_vm._v("? ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }